import React from 'react'
import { graphql, navigate } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import '../css/start-now.scss'
import { Navbar } from '../components/navbar'
import bgImage from '../images/idengager-free-trial-bg.png'
import '../css/trial.scss'
import { Form, Input, Select, Button, Spin, InputNumber, Checkbox } from 'antd'
import countries from '../utils/countries'
import { isAuthenticated, login, getUserInfo, getAccessToken, getIdToken } from '../components/util/auth'
import updateStripeCustomer from '../components/util/updateStripeCustomer'
import createSubscription from '../components/util/createSubscription'
import axios from 'axios'
import tracking from '../utils/tracking'
import { usePlans } from '../hooks/stripe'

const companySizeOptions = [
	{
		value: '1-10',
		label: '1 - 10',
	},
	{
		value: '11-100',
		label: '11-100',
	},
	{
		value: '100+',
		label: '100+',
	},
]

const StartNowPage = ({ data }) => {
	const [isLoading, setIsLoading] = React.useState(false)
	const [tenantData, setTenantDta] = React.useState(null)
	const { data: plans, isLoading: isPlansLoading } = usePlans()
	const [isSubscribed] = React.useState(
		plans?.find(plan => plan.nickname === 'Customer Engagement' && plan.isSubscribed)
	)
	const isSubscriptionsChecked = React.useRef(false)

	const user = getUserInfo()

	React.useEffect(() => {
		if (!isAuthenticated()) {
			login()
		}
	}, [])

	React.useEffect(() => {
		if (!plans || isSubscriptionsChecked.current) return

		const subscription = plans.find(plan => plan.nickname === 'Customer Engagement' && plan.subscriptionData)

		// console.log(subscription)

		if (subscription) {
			navigate('/account')
		}

		isSubscriptionsChecked.current = true
	}, [plans, user])

	const handleSubmit = async values => {
		setIsLoading(true)

		await updateStripeCustomer({
			customerId:
				process.env.NODE_ENV === 'production'
					? user?.['https://icloud-ready.com/stripe_customer_id']
					: user?.['https://icloud-ready.com/stripe_customer_id2'],
			metadata: values,
		})

		await createSubscription({
			stripeId:
				process.env.NODE_ENV === 'production'
					? user?.['https://icloud-ready.com/stripe_customer_id']
					: user?.['https://icloud-ready.com/stripe_customer_id2'],
			customerEmail: user?.email,
			lineItems: [
				{
					price: process.env.IDENGAGER_PRICE_ID,
					quantity: 1,
				},
			],
			subscription: {
				items: [
					{
						plan: process.env.IDENGAGER_PRICE_ID,
					},
				],
			},
		})

		const { data } = await axios.post('/.netlify/functions/createIdengagerTenant', {
			...values,
			email: user.email,
		})

		// console.log({ realmData: data })

		updateStripeCustomer({
			customerId:
				process.env.NODE_ENV === 'production'
					? user?.['https://icloud-ready.com/stripe_customer_id']
					: user?.['https://icloud-ready.com/stripe_customer_id2'],
			metadata: {
				...values,
				idengager: JSON.stringify(data),
			},
		})

		tracking.trackConversion('free_trial')

		setTenantDta(data)

		setIsLoading(false)
	}

	if (!plans || isPlansLoading || !user || isSubscribed) {
		return (
			<div
				style={{
					minHeight: '100vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Spin size="large" />
			</div>
		)
	}

	return (
		<>
			<Layout>
				<SEO title="free-trial" description="Start your free trial with icloud-ready" />
				<div className="start-now-page main-wrapper">
					<Navbar />
					<div
						className="page-content"
						style={{ background: `url(${bgImage}) no-repeat center / cover` }}
					>
						<div className="section-parent section-container">
							{!tenantData && (
								<div className="wrapper">
									<h2>Start Your Free Trial Now !</h2>

									<h3>
										Join iCloud-Ready CRM And Start Engaging with your
										customers.
									</h3>

									<Form layout="vertical" onFinish={handleSubmit}>
										<Form.Item
											name="firstname"
											label="First Name"
											className="field-parent"
											style={{ width: '100%' }}
											rules={[
												{
													required: true,
													message: 'Required!',
												},
											]}
										>
											<Input />
										</Form.Item>

										<Form.Item
											name="lastname"
											label="Last Name"
											className="field-parent"
											style={{ width: '100%' }}
											rules={[
												{
													required: true,
													message: 'Required!',
												},
											]}
										>
											<Input />
										</Form.Item>

										<Form.Item
											name="companyName"
											label="Company Name"
											className="field-parent"
											style={{ width: '100%' }}
											rules={[
												{
													required: true,
													message: 'Required!',
												},
											]}
										>
											<Input />
										</Form.Item>

										<Form.Item
											name="companySize"
											label="Company Size"
											className="field-parent"
											style={{ width: '100%' }}
											rules={[
												{
													required: true,
													message: 'Required!',
												},
											]}
										>
											<Select options={companySizeOptions} />
										</Form.Item>

										<Form.Item
											name="country"
											label="Country"
											className="field-parent"
											style={{ width: '100%' }}
											rules={[
												{
													required: true,
													message: 'Required!',
												},
											]}
										>
											<Select
												showSearch
												options={countries.map(country => {
													return {
														value: country.name,
														name: country.name,
													}
												})}
											/>
										</Form.Item>

										<Form.Item
											name="phone"
											label="Phone"
											className="field-parent"
											style={{ width: '100%' }}
											rules={[
												{
													required: true,
													message: 'Required!',
												},
											]}
										>
											<InputNumber style={{ width: '100%' }} />
										</Form.Item>

										<Form.Item
											name="demoApps"
											valuePropName="checked"
											style={{ width: '100%' }}
										>
											<Checkbox>Add demo apps?</Checkbox>
										</Form.Item>

										<Form.Item style={{ width: '100%' }}>
											<Button
												htmlType="submit"
												type="primary"
												style={{ width: '100%' }}
												loading={isLoading}
												disabled={isLoading}
											>
												Start Now
											</Button>
										</Form.Item>
									</Form>
								</div>
							)}

							{tenantData && (
								<div className="wrapper">
									<div className="success-container">
										<h3>
											Congratulations! Your Tenant Has Been
											Created Successfully
										</h3>

										<div className="">
											<a
												href={
													process.env
														.IDENGAGER_REDIRECT_URL
												}
												target="_blank"
											>
												<Button
													htmlType="submit"
													type="primary"
													style={{ width: '100%' }}
												>
													Explore Now
												</Button>
											</a>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="footer-section">
						<MDXRenderer>{data.Footer.nodes[0].body}</MDXRenderer>
					</div>
				</div>
			</Layout>
		</>
	)
}

export const pageQuery = graphql`
	query CustomerEngagementFreeTrialQuery {
		Header: allMdx(filter: { frontmatter: { title: { eq: "Header" } } }) {
			nodes {
				body
			}
		}
		Footer: allMdx(filter: { frontmatter: { title: { eq: "Footer" } } }) {
			nodes {
				body
			}
		}
	}
`

function withBrowser(Page) {
	return function WithBrowser(props) {
		const [isMounted, setIsMounted] = React.useState(false)

		React.useEffect(() => {
			setIsMounted(true)
		}, [])

		return isMounted && <Page {...props} />
	}
}

export default withBrowser(StartNowPage)
